import axios from '../axios/index.js'
export default {


    getmeetings_statistics() {
        return axios.get(`/meetings_statistics`)
    },
    getmeetings_calender() {
        return axios.get(`/meetings_calender`)
    },



}