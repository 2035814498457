<template>
  <div class="dashboard">
    <!--begin::Dashboard-->
    <div
      class="pages_header member-tablet-view d-flex align-items-center justify-content-between"
    >
      <h3 class="page_title Centering">نظره عامة</h3>

      <div class="">
        <button
          class="btn_Green btn_main  "
          @click="$router.push('/new_meeting')"
        >
            <i class="fas fa-plus"></i>
                    <span>
            إجتماع جديد
          
          </span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 col-md-6 col-lg-3  col-xxl-3 col-12 mb-3">
        <div class="info_card">
          <img class="card_corner" src="/media/svg/Group_green.svg" />
          <i class="icon fas fa-user"></i>
          <div class="card_detailes">
            <h3>{{ statistics.users }}</h3>
            <h4>عدد المستخدمين</h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-3  col-xxl-3 col-12 mb-3">
        <div class="info_card">
          <img class="card_corner" src="/media/svg/Group_green.svg" />
        <i class="icon fas fa-chalkboard-teacher"></i>
          <div class="card_detailes">
            <h3>{{ statistics.meetings_count }}</h3>
            <h4>عدد الإجتماعات</h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-3  col-xxl-3 col-12 mb-3">
        <div class="info_card">
          <img class="card_corner" src="/media/svg/Group_green.svg" />

          <i class="icon fas fa-laptop"></i>
          <div class="card_detailes">
            <h3>{{ statistics.today_meetings }}</h3>
            <h4>الإجتماعات الحالية</h4>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-3  col-xxl-3 col-12 mb-3">
        <div class="info_card">
          <img class="card_corner" src="/media/svg/Group_green.svg" />
          <i class="icon fas fa-laptop"></i>
          <div class="card_detailes">
            <h3>{{ statistics.incomming_meetings }}</h3>
            <h4>الإجتماعات القادمة</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="calender">
      <!-- <FullCalendar :options="calendarOptions" /> -->
      <FullCalendar
        :options="calendarOptions"
        class="demo-app-calendar"
        ref="fullCalendar"
        :header="{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }"
      />
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import dashboard from "../../http/requests/dashboard";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// must manually include stylesheets for each plugin
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

export default {
  name: "dashboard",
  // components: {
  //   v-input,
  // },
  // mounted() {
  //   this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  // },
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      statistics: {},
      calendarOptions: {
        locale: "ar",
        initialView: "dayGridMonth",
        plugins: [
          // plugins must be defined in the JS
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        weekends: true,
        events: [],
      },
    };
  },
  methods: {
    // meetings_statistics
    getmeetings_statistics() {
      this.statistics = {};
      this.$vs.loading();
      dashboard
        .getmeetings_statistics()
        .then((res) => {
          this.statistics = res.data;
          this.$vs.loading.close();
        })
              .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    },
    getmeetings_calender() {
      this.calendarOptions.events = [];
      this.$vs.loading();
      dashboard
        .getmeetings_calender()
        .then((res) => {
          let temp = [];
          for (let i = 0; i < res.data.data.length; i++) {
            temp.push({
              url: `/#/view_meeting/${res.data.data[i].slug}`,
              start: res.data.data[i].start,
              title: res.data.data[i].title,
            });
          }
        
          this.calendarOptions.events = temp;
          this.$vs.loading.close();
        })
               .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
              
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
    
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getmeetings_statistics();
    this.getmeetings_calender();
  },
  created() {
    
    this.getmeetings_statistics();
    this.getmeetings_calender();
  },
};
</script>
